.aboutus{
    padding: 40px 0px;
}
.abouttext p{
    text-align: center;
    line-height: 26px;
    font-size: 18px;
    font-weight: 300;
    color: #000000;
  align-items: center;
 
  padding-bottom: 30px;
  margin: 0 auto;
    font-family: var(--font-primary);
}
.abouttext{
    gap: 57;
    padding-bottom: 80px;
}