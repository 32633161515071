.contact-page-wrapper .contact-form-wrap {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    padding: 71px 60px 14px;
    z-index: 1;
    margin-bottom: 40px;
}

.contact-info-wrap .contact-info .info-item {
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: #fff;
    margin-bottom: 30px;
    padding: 40px 30px 32px;
    text-align: center;
}

.contact-info-wrap .contact-info .info-item .icon {
    margin-bottom: 26px;
}

.contact-info-wrap .contact-info .info-item .info .title {
    font-weight: 500;
    font-size: 24px;
    color: #2e2e2e;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.contact-info-wrap .contact-info .info-item .info p {
    color: #5b5252;
    font-size: 15px;
    line-height: 1.8125;
}

.contact-info-wrap .contact-info .info-item .info a {
    color: #5b5252;
    line-height: 1.8125;
}

.contact-area {
    margin-top: 30px;
    margin-bottom: 90px;
}

.contact-form .form-group {
    margin-bottom: 30px;
}

.contact-form .form-group .form-control {
    /* border: 1px solid #d7d7d7; */
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    color: #999999;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 0.14px;
    height: 58px;
    line-height: 49px;
    padding: 8px 24px;
    transition: all 0.3s ease-out;
    margin-top: 12px;

}

@media only screen and (max-width: 1199px) {
    .contact-form .form-group textarea.form-control {
        height: 198px;
    }


}

.contact-form .form-group textarea.form-control {
    height: 250px;
    line-height: 24px;
    resize: inherit;
    padding-top: 22px;
}

.contact-area .col-xl-3 {
    display: block;
}

#contact-form {
    margin-bottom: 15px;
}

.btn-contact {
    padding: 10px 30px;
    background-color: transparent;
    color: black;
    border: 1px solid black;

    font-size: 16px;
}

.btn-contact:hover {
    background-color: black;
    color: white;
}

@media(max-width:700px) {
    .shape-group-one {
        display: none;
    }

    .shape-group-two {
        display: none;
    }

}

@media(max-width:320px) {

    .btn-contact {
        font-size: 7px;
    }

    .contact-form .form-group textarea.form-control {
        height: 100px !important;
        line-height: 24px;
        resize: inherit;
        padding-top: 22px;
    }

    .sale-link p {
        font-size: 12px !important;
    }
}

@media(max-width:425px) {
    .bannersection {
        padding: 35px 0 20px !important;
    }

    .contact-page-wrapper .contact-form-wrap .contact-form-title .title {
        font-size: 20px !important;
        text-align: center;

    }

    .contact-form .form-group textarea.form-control {
        height: 140px;
        line-height: 24px;
        resize: inherit;
        padding-top: 22px;
    }

    .btn-contact {
        padding: 6px 20px;
    }

    .about-content .sub-title {
        font-size: 25px !important;
        margin-top: 20px;

    }

    .sale-link p {
        font-size: 15px;
    }

    .about-area .titlee {
        font-size: 30px !important;
        margin-bottom: 7px !important;
    }

    .section-title .titleone {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .bannertitle {
        font-size: 20px !important;
    }

    .team-item .contentteam .titleteam {
        font-size: 16px;
    }

    .signup h2 {
        font-size: 25px !important;
    }
}

@media(max-width:375px) {

    .contact-form .form-group textarea.form-control {
        height: 120px;
        line-height: 24px;
        resize: inherit;
        padding-top: 22px;
    }

    .btn-contact {
        padding: 6px 20px !important;
    }

    .bannertitle {
        font-size: 18px !important;
    }

    .bannfirstlink {
        font-size: 18px;
    }

    .bannlink {
        font-size: 18px !important;
    }

    .sale-link p {
        font-size: 14px;
    }


}

@media(max-width:768px) {

    .contact-page-wrapper .contact-form-wrap .contact-form-title .title {
        font-size: 28px;
    }

    .bannlink {
        font-size: 18px !important;
    }

    .bannfirstlink {
        font-size: 18px !important;
    }

    .contact-form .form-group {
        margin-bottom: 0;
    }
    .contact-form .form-group .form-control {
        height: 40px;
    }

    .contact-form .form-group.last {
        margin-top: 20px;
    }

    .contact-page-wrapper .contact-form-wrap {
        padding: 20px;
    }
}