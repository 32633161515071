.header {
  -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--white);
}

.header .header-block {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 20px 0
}

.header .header-block .logo img {
  max-width: 250px;
}

.header .header-block .logo h1 span {
  color: var(--dark)
}

@media screen and (max-width: 767px) {
  .header .header-block .nav-bar {
    display: none
  }
  .header .header-block .logo img {
    max-width: 180px;
  }
}

.header .header-block .nav-bar .nav-links {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: end;
  -moz-justify-content: end;
  justify-content: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  gap: 40px
}

.header .header-block .mobile-nav {
  display: none
}

@media screen and (max-width: 767px) {
  .header .header-block .mobile-nav {
    display: block
  }
}

.header .header-block .mobile-nav .mobile-menu {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  gap: 5px;
  overflow: hidden
}

.header .header-block .mobile-nav .mobile-menu span {
  width: 30px;
  height: 2px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background-color: var(--dark);
  -webkit-transition: .4s ease all;
  -moz-transition: .4s ease all;
  -ms-transition: .4s ease all;
  -o-transition: .4s ease all;
  transition: .4s ease all
}

.header .header-block .mobile-nav .mobile-menu span:first-child {
  width: 20px
}

.header .header-block .mobile-nav .mobile-menu.active {
  height: 30px;
  height: 30px
}

.header .header-block .mobile-nav .mobile-menu.active span {
  width: 25px
}

.header .header-block .mobile-nav .mobile-menu.active span:first-child {
  width: 25px;
  transform: rotate(45deg) translate(5px, 5px)
}

.header .header-block .mobile-nav .mobile-menu.active span:last-child {
  transform: rotate(-45deg) translate(5px, -5px)
}

.header .header-block .mobile-nav .mobile-menu.active span:nth-child(2) {
  transform: translateX(100%)
}

.mobile-menubar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--dark);
  padding: 20px;
  padding-top: 160px;
  z-index: 9;
  transform: translateX(-100%);
  -webkit-transition: .5s ease all;
  -moz-transition: .5s ease all;
  -ms-transition: .5s ease all;
  -o-transition: .5s ease all;
  transition: .5s ease all;
  display: none
}

.mobile-menubar.active {
  transform: translateX(0)
}

@media screen and (max-width: 767px) {
  .mobile-menubar {
    display: block
  }
}

.mobile-menubar .nav-links {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px
}

.mobile-menubar .nav-links .nav-link {
  display: block;
  color: var(--white);
}

.mobile-menubar .nav-links .nav-link.common-btn {
  display: inline-block;
  margin-top: 10px;
  border-color: var(--white);
}