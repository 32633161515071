/* secfirst css */
.banner-section {
  overflow: hidden;
  padding-top: 50px;
  position: relative;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.slick-slide img {
  width: 100%;
  object-fit: cover;
}

.banner-section .slick-slide img {
  min-height: 500px;
}

.slide-content {
  position: absolute;
  top: 45% !important;
  text-align: center;
  color: var(--white);
  z-index: 1;
}

.slide-content h2 {
  font-size: 40px;
  margin-bottom: 10px;
  color: white;
}

.slide-content p.heading {
  font-size: 28px;
  line-height: 35px;
  align-items: center;
  font-weight: 300 !important;
  font-family: var(--font-secondary) !important;
  margin-bottom: 20px;
}

.static-arrow {
  position: absolute;
  top: 49%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: 1px solid black;
  color: black;
  text-align: center;
  line-height: 35px;
  font-size: 18px;
  cursor: pointer;

}

.left-arrow {
  left: 190px;
}

.right-arrow {
  right: 190px;
}

.slick-slide {
  position: relative;
}

.slick-slide img {
  position: relative;
  width: 100%;
  display: block;
}

.banner-section .slick-slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.slide-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--white);
  z-index: 1;
  max-width: 585px;
}

.mainborder {
  padding-top: 60px;
  border-bottom: 1px solid black;
}

/* about-sectiontion */
.about-section {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.imagecontainer {
  width: calc(50% - 25px);
  display: flex;
  align-items: center;
  gap: 30px;
}

.imagecontainer img {
  width: calc(50% - 15px);
}

.textcontainer {
  width: calc(50% - 25px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textcontainer h2 {
  padding-bottom: 25px;
}

.textcontainer p {
  padding-bottom: 30px;
}

.title button {
  font-family: var(--font-three) !important;
  padding: 10px 15px 10px 15px;
  font-size: 16px;
  list-style: 19.36px;
  font-weight: 400;
  width: 175px;
  height: 50px;
  background-color: transparent;
  border: 1px solid black;
}

@media screen and (max-width: 1200px) {
  .slide-content {
    max-width: 80%;
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .about-section {
    gap: 40px;
  }

  .imagecontainer {
    width: 100%;
  }

  .textcontainer {
    width: 100%;
  }

  .textcontainer {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .about-section {
    flex-direction: column;
  }
}

/* series-section css */
.col-md-3 {
  width: 25%;
  float: left;
  padding: 0 15px;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.series-section .row,
.collection-section .row {
  row-gap: 30px;
}

.row>[class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .col-md-3 {
    flex-basis: calc(33.3333%);
  }

  .slide-content p.heading {
    font-size: 24px;
    line-height: 1.7;
  }

  .cart-item img {
    height: 300px;
  }

  .static-arrow {
    display: none;
  }

}

@media (max-width: 767px) {
  .slide-content p.heading {
    font-size: 20px;
    line-height: 1.7;
  }

  .col-md-3 {
    flex-basis: 50%;
    padding: 0 10px;
  }

  .mainborder {
    padding: 12px 0px;
  }

  .cart-item img {
    height: 200px;
  }

  .title h2 {
    font-size: 20px;
    line-height: 1.2;
  }

  .title button {
    padding: 8px;
    margin: 10px;
    font-size: 13px;
  }

  .series-section .row,
  .collection-section .row {
    row-gap: 20px;
  }

  .row>[class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
  }

  .imagecontainer {
    gap: 10px;
  }

  .imagecontainer img {
    width: calc(50% - 5px);
  }
}

.title button:hover {
  color: white;
  background-color: black;
}

/* team-section css */
.team-item {
  border: 1px solid var(--gray);
  padding: 20px;
}


/* .team-section .slick-slider {
  margin: 0 -15px;
}

.team-section .slick-slide {
  margin-right: 15px;
  margin-left: 15px;
} */

.team-image {
  transition: transform 0.3s ease;
}


.team-item:hover .team-image {
  transform: scale(1.1);
}

.team-item:hover .team-text {
  opacity: 1;
}

.team-text {
  font-size: 24px;
  font-weight: 400;
  margin-top: 20px;
  letter-spacing: 0em;
  text-align: center;
  font-family: var(--font-primary) !important;
}

/* newsec */
.product-item {
  margin-bottom: 36px;
}

.product-item .inner-content .product-thumb {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid #e1e1e1;
  background-color: var(--white);
  border-radius: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.product-item .inner-content .product-thumb img {
  border-radius: 13px;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  width: 100%;
}

.product-item .inner-content .product-info .title {
  font-size: 16px;
  margin-bottom: 15px;
}