.products-list {
    padding-bottom: 100px;
}

 .bannersection {
    padding: 100px 0 20px;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 40px;
    text-align: center;
}

.products-list .list-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 40px;
}

.products-list .list-block .sidebar {
    max-width: 320px;
    width: 100%;
}

.products-list .list-block .product-list {
    width: -webkit-fill-available;
}

.products-list .list-block .sidebar .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 40px;
}

.products-list .list-block .sidebar .heading .text {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    font-family: var(--font-secondary);
    color: var(--dark);
}

.products-list .list-block .sidebar .heading .clearbtn {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    color: var(--gray);
    font-weight: 300;
    cursor: pointer;
}

.products-list .list-block .sidebar .heading .clearbtn img {
    width: 15px;
}

.sidebar .filter-item:not(:last-child) {
    margin-bottom: 50px;
}

.sidebar .filter-item .title {
    border-bottom: 1px solid var(--gray);
    font-size: 20px;
    color: var(--gray);
    padding-bottom: 5px;
    margin-bottom: 20px;
}

.product-list .search-box {
    position: relative;
}

.product-list .search-box input {
    width: 100%;
    border-radius: 0;
    border: 1px solid var(--gray);
    padding: 5px 50px 5px 20px;
    min-height: 50px;
    font-size: 20px;
}

.product-list .search-box svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 30px;
}

.product-list .productList {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.product-list .productList .product-card {
    width: calc(33.33% - (80px / 3));
}

.product-list .productList {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.product-list .productList .product-card {
    width: calc(33.33% - (80px / 3));
}

.product-list .result-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin-top: 40px;
    border-top: 1px solid var(--gray);
}

.product-list .result-info {
    color: var(--gray);
}

.product-list .result-info span {
    color: var(--dark);
}

.sidebar .filter-item .checkbox input {
    display: none;
}

.sidebar .filter-item .checkbox .form-check {
    margin: 0;
    padding: 0;
}

.sidebar .filter-item .checkbox input+label {
    position: relative;
    padding-left: 35px;
    width: 100%;
    color: var(--gray);
}

.sidebar .filter-item .checkbox input:checked+label {
    color: var(--dark);
}

.sidebar .filter-item .checkbox input+label::after {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid var(--gray);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.sidebar .filter-item .checkbox input:checked+label::after {
    border-color: var(--dark);
}

.sidebar .filter-item .checkbox input+label::before {
    content: "";
    width: 12px;
    height: 6px;
    border: 2px solid var(--dark);
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-80%) rotate(-45deg);
    border-top: none;
    border-right: none;
    display: none;
}

.sidebar .filter-item .checkbox input:checked+label::before {
    display: block;
}

.sidebar .filter-item .checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.viewmore-btn {
    padding: 10px 0 0 5px;
}

.viewmore-btn p {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 16px;
    color: var(--gray);
}

.viewmore-btn p.less img {
    transform: rotate(180deg);
}

.sidebar .filter-item .checkbox-list.all {
    max-height: 300px;
    overflow: auto;
}

.product-top-bar .text {
    display: flex;
    align-items: center;
    width: 100px;
    gap: 10px;
    font-size: 24px;
    color: var(--dark);
}

.checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.viewmore-btn p {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 16px;
    color: var(--gray);
}

.viewmore-btn p.less img {
    transform: rotate(180deg);
}

.sidebar .filter-item .checkbox-list.all {
    max-height: 300px;
    overflow: auto;
}

.product-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding-bottom: 30px;
}

.product-top-bar .search-box {
    width: -webkit-fill-available;
}

.product-top-bar .text {
    display: flex;
    align-items: center;
    width: 100px;
    gap: 10px;
    font-size: 24px;
    color: var(--dark);
    display: none;
}

.products-list .list-block .sidebar .heading .text p,
.product-top-bar .text p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
}

span.filter-count {
    font-size: 14px;
    height: 10px;
    min-width: 10px;
    background: var(--gray);
    text-align: center;
    color: var(--white);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 3px;
}

.close {
    display: none;
}

@media screen and (max-width: 1199px) {
    .products-list .list-block .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        height: calc(100vh - 500px);
        z-index: 99999;
        background-color: var(--white);
        padding: 50px 20px;
        max-width: 400px;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .1);
        overflow-y: scroll;
        height: 100vh;
        transform: translateX(-110%);
        -webkit-transition: 0.4s ease all;
        -moz-transition: 0.4s ease all;
        -ms-transition: 0.4s ease all;
        -o-transition: 0.4s ease all;
        transition: 0.4s ease all;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        font-size: 24px;
        width: 30px;
        height: 30px;
        display: block;
    }

    .products-list .list-block .sidebar.mobile-avtive {
        transform: translateX(0);
    }

    .product-top-bar .text {
        display: flex;
    }

    .product-list .productList {
        gap: 30px;
    }

    .product-list .productList .product-card {
        width: calc(33.33% - (60px / 3));
    }
}

@media screen and (max-width: 767px) {
    .product-list .search-box svg {
        font-size: 24px;
        right: 10px;
    }

    .product-list .search-box input {
        padding: 5px 30px 5px 20px;
    }

    .products-list .list-block .sidebar {
        max-width: 100%;
        right: 0;
        left: 0;
        top: 200px;
        bottom: 0;
        height: calc(100vh - 200px);
        overflow-y: scroll;
        transform: translateX(0) translateY(110%);
    }

    .products-list .list-block .sidebar.mobile-avtive {
        transform: translateX(0) translateY(0);
    }

    .product-list .productList {
        gap: 20px;
    }

    .product-list .productList .product-card {
        width: calc(50% - 10px);
    }

    .product-list .result-block {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .product-list .search-box input {
        min-height: 40px;
        font-size: 16px;
    }

    .products-list .banner-section {
        padding-top: 70px;
    }
}