@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Bold.eot');
  src: url('./Fonts/Jost-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Bold.woff2') format('woff2'),
    url('./Fonts/Jost-Bold.woff') format('woff'),
    url('./Fonts/Jost-Bold.ttf') format('truetype'),
    url('./Fonts/Jost-Bold.svg#Jost-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Regular.eot');
  src: url('./Fonts/Jost-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Regular.woff2') format('woff2'),
    url('./Fonts/Jost-Regular.woff') format('woff'),
    url('./Fonts/Jost-Regular.ttf') format('truetype'),
    url('./Fonts/Jost-Regular.svg#Jost-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-SemiBold.eot');
  src: url('./Fonts/Jost-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-SemiBold.woff2') format('woff2'),
    url('./Fonts/Jost-SemiBold.woff') format('woff'),
    url('./Fonts/Jost-SemiBold.ttf') format('truetype'),
    url('./Fonts/Jost-SemiBold.svg#Jost-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Italic.eot');
  src: url('./Fonts/Jost-Italic.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Italic.woff2') format('woff2'),
    url('./Fonts/Jost-Italic.woff') format('woff'),
    url('./Fonts/Jost-Italic.ttf') format('truetype'),
    url('./Fonts/Jost-Italic.svg#Jost-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Black.eot');
  src: url('./Fonts/Jost-Black.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Black.woff2') format('woff2'),
    url('./Fonts/Jost-Black.woff') format('woff'),
    url('./Fonts/Jost-Black.ttf') format('truetype'),
    url('./Fonts/Jost-Black.svg#Jost-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Medium.eot');
  src: url('./Fonts/Jost-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Medium.woff2') format('woff2'),
    url('./Fonts/Jost-Medium.woff') format('woff'),
    url('./Fonts/Jost-Medium.ttf') format('truetype'),
    url('./Fonts/Jost-Medium.svg#Jost-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Thin.eot');
  src: url('./Fonts/Jost-Thin.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Thin.woff2') format('woff2'),
    url('./Fonts/Jost-Thin.woff') format('woff'),
    url('./Fonts/Jost-Thin.ttf') format('truetype'),
    url('./Fonts/Jost-Thin.svg#Jost-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-ExtraBold.eot');
  src: url('./Fonts/Jost-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-ExtraBold.woff2') format('woff2'),
    url('./Fonts/Jost-ExtraBold.woff') format('woff'),
    url('./Fonts/Jost-ExtraBold.ttf') format('truetype'),
    url('./Fonts/Jost-ExtraBold.svg#Jost-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-Light.eot');
  src: url('./Fonts/Jost-Light.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-Light.woff2') format('woff2'),
    url('./Fonts/Jost-Light.woff') format('woff'),
    url('./Fonts/Jost-Light.ttf') format('truetype'),
    url('./Fonts/Jost-Light.svg#Jost-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Jost';
  src: url('./Fonts/Jost-ExtraLight.eot');
  src: url('./Fonts/Jost-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/Jost-ExtraLight.woff2') format('woff2'),
    url('./Fonts/Jost-ExtraLight.woff') format('woff'),
    url('./Fonts/Jost-ExtraLight.ttf') format('truetype'),
    url('./Fonts/Jost-ExtraLight.svg#Jost-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Thin.eot');
  src: url('./Fonts/GeologicaRoman-Thin.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Thin.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Thin.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Thin.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Thin.svg#GeologicaRoman-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-SemiBold.eot');
  src: url('./Fonts/GeologicaRoman-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-SemiBold.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-SemiBold.woff') format('woff'),
    url('./Fonts/GeologicaRoman-SemiBold.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-SemiBold.svg#GeologicaRoman-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Light.eot');
  src: url('./Fonts/GeologicaRoman-Light.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Light.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Light.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Light.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Light.svg#GeologicaRoman-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Regular.eot');
  src: url('./Fonts/GeologicaRoman-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Regular.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Regular.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Regular.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Regular.svg#GeologicaRoman-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Medium.eot');
  src: url('./Fonts/GeologicaRoman-Medium.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Medium.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Medium.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Medium.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Medium.svg#GeologicaRoman-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Black.eot');
  src: url('./Fonts/GeologicaRoman-Black.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Black.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Black.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Black.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Black.svg#GeologicaRoman-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-ExtraLight.eot');
  src: url('./Fonts/GeologicaRoman-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-ExtraLight.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-ExtraLight.woff') format('woff'),
    url('./Fonts/GeologicaRoman-ExtraLight.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-ExtraLight.svg#GeologicaRoman-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-Bold.eot');
  src: url('./Fonts/GeologicaRoman-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-Bold.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-Bold.woff') format('woff'),
    url('./Fonts/GeologicaRoman-Bold.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-Bold.svg#GeologicaRoman-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geologica Roman';
  src: url('./Fonts/GeologicaRoman-ExtraBold.eot');
  src: url('./Fonts/GeologicaRoman-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./Fonts/GeologicaRoman-ExtraBold.woff2') format('woff2'),
    url('./Fonts/GeologicaRoman-ExtraBold.woff') format('woff'),
    url('./Fonts/GeologicaRoman-ExtraBold.ttf') format('truetype'),
    url('./Fonts/GeologicaRoman-ExtraBold.svg#GeologicaRoman-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



:root {
  --dark: #151414;
  --white: #FFFFFF;
  --gray: #808184;
  --font-primary: "jost";
  --font-secondary: 'Geologica Roman';
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

a,
a::before,
a::after,
button,
:hover,
:hover:before,
:hover:after {
  -webkit-transition: .4s ease all;
  -moz-transition: .4s ease all;
  -ms-transition: .4s ease all;
  -o-transition: .4s ease all;
  transition: .4s ease all
}

:focus {
  outline: none !important;
  box-shadow: none !important
}

body {
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: normal;
  font-family: var(--font-primary);
  -webkit-transition: all .5s ease-in-out 0s;
  -moz-transition: all .5s ease-in-out 0s;
  -ms-transition: all .5s ease-in-out 0s;
  -o-transition: all .5s ease-in-out 0s;
  transition: all .5s ease-in-out 0s;
  overflow-x: hidden;
}

p {
  margin: 0
}

.container {
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  overflow: hidden;
}

.container-fluid {
  padding: 0;
  overflow: hidden
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none
}

input[type=text],
input[type=password],
input[type=url],
input[type=tel],
input[type=search],
input[type=number],
input[type=datetime],
input[type=email] {
  font-weight: normal;
  font-size: 16px
}

a {
  text-decoration: none;
  display: inline-block;
}

a:hover {
  text-decoration: none;
  color: var(--dark);
}

img {
  max-width: 100%;
  vertical-align: top
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
  font-family: var(--font-secondary);
}

h1 {
  font-size: 42px;
  line-height: 52px
}

h2 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 300;
}

h3 {
  font-size: 35px;
  line-height: 40px
}

h4 {
  font-size: 24px;
  line-height: 34px
}

h5 {
  font-size: 18px;
  line-height: 20px
}

h6 {
  font-size: 18px;
  line-height: 22px
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 16px
  }
}

@media screen and (max-width: 1024px)and (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 22px
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 38px;
    line-height: 44px
  }
}

@media screen and (max-width: 1024px)and (max-width: 767px) {
  h1 {
    font-size: 34px;
    line-height: 40px
  }
}

@media screen and (max-width: 1024px) {
  h2 {
    font-size: 30px;
    line-height: 35px
  }

  h3 {
    font-size: 28px;
    line-height: 32px
  }
}

@media screen and (max-width: 1024px)and (max-width: 767px) {
  h2 {
    font-size: 24px;
    line-height: 32px
  }

  h3 {
    font-size: 24px;
    line-height: 32px
  }
}

@media screen and (max-width: 1024px) {
  h4 {
    font-size: 22px;
    line-height: 28px
  }

  h5 {
    font-size: 18px;
    line-height: 20px
  }

  h6 {
    font-size: 16px;
    line-height: 20px
  }
}

.common-btn {
  border: 1px solid var(--dark);
  line-height: 1.7;
  padding: 7px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.common-btn:hover {
  color: var(--white);
  background: var(--dark);
}


.common-btn2 {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  gap: 10px;
  color: var(--dark);
  text-decoration: underline;
}

.common-btn2:hover {
  text-decoration: none;
}

.row {
  display: flex;
}


.pagination-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  border-top: 1px solid #efefef;
  padding-top: 3%;
  margin-top: 3%;
}

.pagination-block .nextui-pagination-highlight--active {
  background-color: var(--primary);
}

.pagination-block .result-info {
  color: #727272;
}

.pagination-block .result-info span {
  color: var(--primary);
  font-weight: bold;
}

.pagination-block .nextui-pagination-highlight {
  background-color: red;
}

.section-gap {
  padding: 80px 0
}

@media screen and (max-width: 1023px) {
  .section-gap {
    padding: 70px 0
  }
}

@media screen and (max-width: 767px) {
  .section-gap {
    padding: 60px 0
  }
}

/*Product Card*/
.product-card {
  width: 100%;
  position: relative;
}

.product-card .product-img img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  border: 1px solid var(--gray);
}

.product-card .product-detail {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--gray);
  min-height: 73px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 20px;
}

.product-card .product-detail p {
  color: var(--dark);
  font-weight: 500;
}

.product-card .product-detail h6 {
  font-weight: 300;
  color: var(--dark);
}

.productList .product-card .product-detail,
.relproduct .product-card .product-detail {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}

@media screen and (max-width: 1200px) {
  .product-card .product-img img {
    height: 400px;
  }
}

@media screen and (max-width: 1024px) {
  .product-card .product-img img {
    height: 380px;
  }
}

@media screen and (max-width: 767px) {
  .product-card .product-img img {
    height: 300px;
  }

  .product-card .product-detail h6 {
    font-size: 14px;
  }

  .product-card .product-detail,
  .productList .product-card .product-detail,
  .relproduct .product-card .product-detail {
    min-height: auto;
    padding: 10px;
    gap: 0;
  }
}

/*Scrollbar Css*/

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

/* preloader */
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: var(--dark);
  top: 0;
  left: 0;
  font-size: 30px;
  vertical-align: center
}

.preloader .loader {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: var(--white);
  pointer-events: none;
  animation: loader-1 2s infinite linear;
  position: absolute;
  top: 45%;
  left: 50%
}

.preloader .loader:before,
.preloader .loader:after {
  content: "";
  width: 0;
  height: 0;
  border: 1rem solid var(--white);
  display: block;
  position: absolute;
  border-radius: 100%;
  animation: loader-2 2s infinite ease-in-out
}

.preloader .loader:before {
  top: 0;
  left: 50%
}

.preloader .loader:after {
  bottom: 0;
  right: 50%;
  animation-delay: -1s
}

@keyframes loader-1 {
  100% {
    transform: rotate(360deg)
  }
}

@keyframes loader-2 {

  0%,
  100% {
    transform: scale(0)
  }

  50% {
    transform: scale(1)
  }
}