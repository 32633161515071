.product-detail-page .img-block {
    text-align: center;
    padding-bottom: 60px;
}

.product-detail-page .img-block img {
    max-width: 440px;
    aspect-ratio: 2/2.5;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
}

.product-detail-page .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--gray);
    margin-bottom: 30px;
}

.product-detail-page .title-bar h2 {
    font-weight: 300;
}

.product-detail-page .title-bar .buttons {
    display: flex;
    gap: 20px;
    align-items: center;
}

.product-detail-page .detail-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.product-detail-page .detail-bar span {
    font-weight: 500;
}

.product-detail-page .detail-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.product-detail-page .detail-bar span {
    font-weight: 500;
}

.related-product {
    padding: 150px 0 50px;
}

.related-product .Heading {
    text-align: center;
    margin-bottom: 40px;
}

.related-product .productList {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.related-product .productList .product-card {
    width: calc(25% - (120px / 4));
}

.product-detail-page .button-block {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    align-items: center;
}

.product-detail-page .button-block a span {
    color: var(--dark);
    font-weight: 500;
}

.product-detail-page .button-block a:hover span {
    color: var(--white);
}

.product-detail-page .buttons-bar {
    display: none;
}

@media screen and (max-width: 1200px) {
    .related-product .productList {
        gap: 30px;
    }

    .related-product .productList .product-card {
        width: calc(25% - (90px / 4));
    }
}

@media screen and (max-width: 991px) {
    .related-product .productList {
        gap: 30px;
    }

    .related-product .productList .product-card {
        width: calc(50% - 15px);
    }
}

@media screen and (max-width: 767px) {
    .product-detail-page .img-block {
        padding-bottom: 40px;
    }

    .product-detail-page .title-bar .buttons {
        display: none;
    }

    .related-product .productList {
        gap: 20px;
    }

    .related-product .productList .product-card {
        width: calc(50% - 10px);
    }

    .product-detail-page .button-block {
        flex-wrap: wrap;
        gap: 10px;
    }

    .product-detail-page .title-bar {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }

    .product-detail-page .detail-bar {
        flex-wrap: wrap;
        gap: 10px;
    }

    .product-detail-page .detail-bar p {
        width: calc(50% - 5px);
    }

    .product-detail-page .detail-bar p:nth-child(odd) {
        text-align: left;
    }

    .product-detail-page .detail-bar p:nth-child(even) {
        text-align: right;
    }

    .related-product {
        padding: 100px 0 40px;
    }

    .product-detail-page .buttons-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 15px;
        margin-top: 20px;
        border-top: 1px solid var(--gray);
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}


.popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}


.closepopup {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 20px;
    cursor: pointer;
    color: black;
}


.form-container {
    width: 600px;
    
}

.popup h3 {
    text-align: center;
    padding-bottom: 30px;
    font-size: 30px;
    font-weight: 400;
    font-family: var(--font-secondary) !important;
}

.form-container input,
.form-container textarea,
.form-container button {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid var(--gray);
   
    
}
textarea.form-control {
    min-height: calc(5.6em + 0.75rem + 2px);
}

.inqbtn {
    background-color: black;
    color:white;
    padding: 10px 40px;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    box-shadow: none;
    margin: 10px auto !important;
    width: 96%;
    border-radius: none;
    font-family: var(--font-primary) !important;
}
.inqbtn:hover{
    background-color:transparent !important;
    color:black !important;
    border: 1px solid black;
}
.form-control{
    border-radius: 0%;
}
/* 404 error message */
.errormessage{
    text-align: center;
    height: 40vh;
}
.errormessage h1{
    font-size: 66px;
    font-weight: 400;
    padding-bottom: 15px;
    padding-top: 40px;
}
.errormessage h2{
    padding-bottom: 15px;
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
.bounce {
  animation: bounce 2s infinite;
}
@media (max-width: 768px) {
    .form-container {
        max-width: 100%; 
    }
    
}
@media (max-width:425px) {
    .form-container .mb-3 .col{
    flex: none;
   }
    .closepopup{
        top: 20px;
        right: 20px;
    }
    .popup h3{
        font-size: 20px;
        padding-bottom: 20px;
    }
}