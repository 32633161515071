.footer {
    background: var(--dark);
    padding-top: 80px;
}

.footer .footer-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.footer .footer-block .footer-logo {
    width: 50%;
}

.footer .footer-block .footer-links {
    width: 20%;
}

.footer .footer-block .footer-contact {
    width: 26%;
}

.footer .footer-block h4 {
    margin-bottom: 40px;
    font-size: 24px;
    color: var(--gray);
    font-weight: 300;
}

.store-link {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 30px;
}

.store-link .store {
    padding: 20px;
    border: 1px solid var(--gray);
}

.store-link .store img {
    width: 120px;
    height: auto;
}

.footer .footer-block a,
.footer .footer-block p {
    font-size: 18px;
    color: var(--white);
    font-weight: 300;
    font-family: var(--font-primary);
}

.footer .footer-block li.item:not(:last-child) {
    margin-bottom: 20px;
}

.footer .footer-contact li.item {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}

.footer .footer-contact li.item img {
    margin-top: 7px;
}

.copyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid var(--gray);
    margin-top: 40px;
}

.copyright p,
.copyright a {
    font-size: 14px;
    line-height: 1;
    color: var(--white);
    font-weight: 300;
}

.copyright a {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--white) !important;
}

@media screen and (max-width: 1200px) {
    .footer .footer-block .footer-logo {
        width: 40%;
    }

    .footer .footer-block .footer-links {
        width: 20%;
    }

    .footer .footer-block .footer-contact {
        width: 35%;
    }

    .footer .footer-block a,
    .footer .footer-block p {
        font-size: 18px;
    }

    .footer .footer-block li.item:not(:last-child) {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 991px) {
    .footer .footer-block {
        flex-wrap: wrap;
    }

    .footer .footer-block h4 {
        margin-bottom: 30px;
    }

    .footer .footer-block .footer-logo {
        width: 100%;
        margin-bottom: 50px;
    }

    .footer .footer-block .footer-links {
        width: 40%;
    }

    .footer .footer-block .footer-contact {
        width: 45%;
    }

    .footer .footer-block a,
    .footer .footer-block p {
        font-size: 18px;
    }

    .footer .footer-block li.item:not(:last-child) {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 767px) {
    .footer .footer-block {
        flex-wrap: wrap;
        gap: 50px;
    }

    .footer .footer-block h4 {
        margin-bottom: 25px;
        font-size: 18px;
    }

    .footer .footer-block .footer-logo {
        width: 100%;
        margin-bottom: 0px;
    }

    .footer .footer-block .footer-links {
        width: 100%;
    }

    .footer .footer-block .footer-contact {
        width: 100%;
    }

    .footer .footer-block a,
    .footer .footer-block p {
        font-size: 14px;
    }

    .footer .footer-block li.item:not(:last-child) {
        margin-bottom: 15px;
    }

    .copyright {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .copyright p,
    .copyright a {
        font-size: 12px;
    }
}